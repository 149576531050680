<template>
  <!-- Create Program Modal -->
  <div class="modal fade" id="createEducationProgram" tabindex="-1"
       aria-labelledby="createEducationProgramLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Добавление</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="col-md-12 mt-4"
               v-for="(newEducationProgramInfo, index) in educationprogram_form.newEducationProgramInfos"
               :key="index">

            <div class="form-row">
              <div class="form-group row mt-4">
                <label class="col-md-3 col-form-label">Кафедра</label>
                <div class="col-md-9">
                  <select class="form-control form-select" disabled>
                    <option v-for="(item, index) in educationdiscipline_form.departmentField"
                            :value="item.id"
                            :selected="item.id == getDepartmentId"
                            :key="index">{{ item.name_ru }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label class="col-md-3 col-form-label">Набор</label>
                <div class="col-md-9">
                  <select class="form-control form-select" name="admission_year" id="admission_year"
                          @input="changeNewEducationProgramData(newEducationProgramInfo,'admission_year', $event)">
                    <option selected>Выберите год набора</option>
                    <option value="2017">2017</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="create_studylevel" class="col-md-3 col-form-label">Уровень
                  обучения</label>
                <div class="col-md-9">
                  <select class="form-control form-select" id="create_studylevel"
                          @input="changeNewEducationProgramData(newEducationProgramInfo,'study_level_id', $event)">
                    <option v-for="(item, index) in educationprogram_form.studyLevel"
                            :value="item.id"
                            :selected="item.id==newEducationProgramInfo.study_level_id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label class="col-md-3 col-form-label">Область образования</label>
                <div class="col-md-9">
                  <select class="form-control form-select"
                          @input="changeNewEducationProgramData(newEducationProgramInfo,'education_field_id', $event)">
                    <option v-for="(item, index) in educationprogram_form.educationField"
                            :value="item.id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="education_direction" class="col-md-3 col-form-label">Направление
                  подготовки</label>

                <div class="col-md-9">
                  <select class="form-control form-select" id="education_direction"
                          @input="[changeNewEducationProgramData(newEducationProgramInfo,'education_direction_id', $event), changeNewEducationProgramData(newEducationProgramInfo,'education_speciality_code', $event)]">
                    <option v-for="(item, index) in getDirectionType" :value="item.id"
                            :selected="item.id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label class="col-md-3 col-form-label">Группа образовательных программ</label>
                <div class="col-md-9">
                  <select class="form-control form-select"
                          @input="changeNewEducationProgramData(newEducationProgramInfo,'education_groups_id', $event)">
                    <option v-for="(item, index) in educationprogram_form.educationGroups"
                            :value="item.id"
                            :selected="newEducationProgramInfo.education_groups_id==item.id"
                            :key="index">{{ item.code }} {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="create_education_program_semester_type" class="col-md-3 col-form-label">Тип семестра</label>
                <div class="col-md-9">
                  <select class="form-control form-select" id="create_education_program_semester_type"
                          @input="changeNewEducationProgramData(newEducationProgramInfo,'education_program_semester_type_id', $event)">
                    <option selected>Выберите тип семестра</option>
                    <option v-for="(item, index) in educationprogram_form.educationProgramSemesterType"
                            :value="item.id"
                            :selected="item.id==newEducationProgramInfo.education_program_semester_type_id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="create_language" class="col-md-3 col-form-label">Язык
                  обучения</label>
                <div class="col-md-9">
                  <select class="form-control form-select" id="create_language"
                          @input="changeNewEducationProgramData(newEducationProgramInfo,'language_id', $event)">
                    <option v-for="(item, index) in educationprogram_form.languages"
                            :value="item.id"
                            :selected="item.id==newEducationProgramInfo.language_id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="education_speciality_code" class="col-md-3 col-form-label">Код
                  образовательной программы</label>
                <div class="col-md-9">
                  <input id="education_speciality_code" type="text" class="form-control"
                         placeholder="Код образовательной программы"
                         :value="newEducationProgramInfo.education_speciality_code"
                         @change="changeNewEducationProgramData(newEducationProgramInfo,'education_speciality_code', $event)">
                </div>
              </div>
              <div class="form-group row mt-4">
                <label for="education_speciality_name" class="col-md-3 col-form-label">Название
                  образовательной программы</label>
                <div class="col-md-9">
                      <textarea id="education_speciality_name" class="form-control"
                                placeholder="Название образовательной программы"
                                value=""
                                @change="changeNewEducationProgramData(newEducationProgramInfo,'education_speciality_name', $event)"></textarea>
                </div>
              </div>
              <div class="form-group row mt-4">
                <label for="ep_aim" class="col-md-3 col-form-label">Цель образовательной
                  программы</label>
                <div class="col-md-9">
                                            <textarea id="ep_aim" class="form-control"
                                                      placeholder="Цель образовательной программы"
                                                      @change="changeNewEducationProgramData(newEducationProgramInfo,'ep_aim', $event)"></textarea>
                </div>
              </div>


              <div class="form-group row mt-4">
                <label for="partner_university_sop" class="col-md-3 col-form-label">ВУЗ-партнер
                  (СОП)</label>
                <div class="col-md-9">
                  <input id="partner_university_sop" type="text" class="form-control"
                         placeholder="ВУЗ-партнер (СОП)"
                         value=""
                         @change="changeNewEducationProgramData(newEducationProgramInfo,'partner_university_sop', $event)">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="partner_university_ddop" class="col-md-3 col-form-label">ВУЗ-партнер
                  (ДДОП)</label>
                <div class="col-md-9">
                  <input id="partner_university_ddop" type="text" class="form-control"
                         placeholder="ВУЗ-партнер (ДДОП)"
                         value=""
                         @change="changeNewEducationProgramData(newEducationProgramInfo,'partner_university_ddop', $event)">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label class="col-md-3 col-form-label">Форма обучения</label>
                <div class="col-md-9">
                  <select class="form-control form-select"
                          @input="changeNewEducationProgramData(newEducationProgramInfo,'study_form_id', $event)">
                    <option v-for="(item, index) in educationprogram_form.studyForm"
                            :value="item.id"
                            :selected="item.id==newEducationProgramInfo.study_form_id"
                            :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="credit_volume" class="col-md-3 col-form-label">Объем
                  кредитов</label>
                <div class="col-md-9">
                  <input id="credit_volume" type="text" class="form-control"
                         placeholder="Объем кредитов"
                         value=""
                         @change="changeNewEducationProgramData(newEducationProgramInfo,'credit_volume', $event)">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="academic_degree" class="col-md-3 col-form-label">Присуждаемая
                  академическая степень</label>
                <div class="col-md-9">
                  <select class="form-control form-select"
                          @input="changeNewEducationProgramData(newEducationProgramInfo,'degree_type_id', $event)">
                    <option v-for="(item, index) in educationprogram_form.degreeTypes"
                            :value="item.id"
                            :selected="newEducationProgramInfo.degree_type_id==item.id"
                            :key="index">{{ item.name_ru }}
                    </option>
                  </select>
<!--                  <input id="academic_degree" type="text" class="form-control"-->
<!--                         placeholder="Присуждаемая академическая степень"-->
<!--                         value=""-->
<!--                         @change="changeNewEducationProgramData(newEducationProgramInfo,'academic_degree', $event)">-->
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="application_availability" class="col-md-3 col-form-label">Наличие
                  приложения к лицензии на направление подготовки кадров</label>
                <div class="col-md-9">
                  <input id="application_availability" type="text" class="form-control"
                         placeholder="Наличие приложения к лицензии на направление подготовки кадров"
                         value=""
                         @change="changeNewEducationProgramData(newEducationProgramInfo,'application_availability', $event)">
                </div>
              </div>

              <div class="form-group row mt-4">
                <label for="credit_volume" class="col-md-3 col-form-label">Приказ ГОСО</label>
                <div class="col-md-9">
                  <select class="form-control form-select" name="decree_goso_templates" id="decree_goso_templates"
                          @input="changeNewEducationProgramData(newEducationProgramInfo,'decree_goso_template_id', $event)">
                    <option v-for="(item, index) in decreeGosoTemplates_form.decreeGosoTemplatesInfos"
                            :value="item.id"
                            :selected="item.id==newEducationProgramInfo.decree_goso_template_id"
                            :key="index">{{ item.decree }}
                    </option>
                  </select>
                </div>
              </div>

            </div>

          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary"
                  @click="submitEducationProgram">
            Создать
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Create Program Modal -->
</template>


<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "AddPassportEducationProgramModal",

  data() {
    return {}
  },
  computed: {
    ...mapState('educationprogram', ['educationprogram_form']),
    ...mapState('decreeGosoTemplates', ['decreeGosoTemplates_form']),
    ...mapState('educationdiscipline', ['educationdiscipline_form']),
    getDirectionType() {
      console.log(this.educationprogram_form.newEducationProgramInfos, 'newEducationProgramInfos')
      console.log(this.educationprogram_form.newEducationProgramInfos[0].education_field_id, 'newEducationProgramInfos')
      let directionType = this.educationprogram_form.educationDirection.filter(i => i.education_field_id == this.educationprogram_form.newEducationProgramInfos[0].education_field_id)
      console.log(directionType, 'directionType')
      return directionType
    },
    getDepartmentId() {
      return getCookie('DEPARTMENT_ID')
    }

  },

  methods: {
    ...mapActions('decreeGosoTemplates', ['GET_DECREE_GOSO_TEMPLATES_DATA']),
    ...mapActions('educationdiscipline', ['GET_DEPARTMENT_NAMES']),
    ...mapActions('educationprogram', ['POST_EDUCATION_PROGRAM_DATA', 'PUT_EDUCATION_PROGRAM_DATA', 'GET_EDUCATION_PROGRAM_DATA',
      'GET_EDUCATION_FIELD_NAMES', 'GET_EDUCATION_DIRECTION_NAMES', 'GET_EDUCATION_GROUPS_NAMES',
      'GET_STUDY_FORM_NAMES', 'GET_STUDY_LEVEL_NAMES', 'GET_EDUCATION_PROGRAM_SEMESTER_TYPE']),
    ...mapMutations('educationprogram', ['UPDATE_NEW_EDUCATION_PROGRAM_DATA', 'SET_DEFAULT_DATA']),

    submitEducationProgram() {

      this.POST_EDUCATION_PROGRAM_DATA().then(res => {
        if (res.success == true) {
          this.GET_EDUCATION_PROGRAM_DATA();
          this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Добавление', text: errorText})
        }
      })
    },

    changeNewEducationProgramData(item, property, e, val = 'value') {
      let value = e.target[val]
      // if (property == 'education_speciality_code') {
      //   value = this.getDirectionType.find(i => i.id == value).name.substr(0, 5);
      // }
      console.log(value, "value")
      this.UPDATE_NEW_EDUCATION_PROGRAM_DATA({item, property, value})
    }
  },
  async mounted() {
    await this.GET_DEPARTMENT_NAMES();
    await this.GET_DECREE_GOSO_TEMPLATES_DATA();
    await this.GET_EDUCATION_PROGRAM_DATA();
    await this.GET_EDUCATION_FIELD_NAMES();
    await this.GET_EDUCATION_DIRECTION_NAMES();
    await this.GET_EDUCATION_GROUPS_NAMES();
    await this.GET_STUDY_FORM_NAMES();
    await this.GET_STUDY_LEVEL_NAMES();
    await this.GET_EDUCATION_PROGRAM_SEMESTER_TYPE();
    this.SET_DEFAULT_DATA(this.decreeGosoTemplates_form)


  }
}
</script>

<style scoped>

</style>
