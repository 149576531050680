<template>
  <div class="container">


    <div class="education-field-form mt-4">
      <h4 class="text-center">Паспорт образовательной программы</h4>

      <DeletePassportEducationProgramModal/>
      <AddPassportEducationProgramModal/>


      <!-- AddDeveloperModal -->
      <div class="modal fade" id="AddDeveloperModal" tabindex="-1"
           aria-labelledby="duplicateEducationProgramModalLabel"
           aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="duplicateEducationProgramModalLabel">
                Добавление разработчика
              </h5>
            </div>
            <div class="modal-body">

              <div>

                <div v-for="(ed,edIndex) in developers" :key="edIndex" class="my-4 border rounded-3 p-2">
                  <div class="mb-2 row">
                    <div class="col-md-10">
                      <select class="form-control form-select" v-model="ed.is_supervisor">
                        <option v-for="(item, index) in typeDevelopers" :value="item.id" :key="index">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <Button icon="pi pi-trash" class="p-button-danger p-button-rounded"
                              @click="deleteDeveloper(edIndex)"/>
                    </div>
                  </div>

                  <div class="mb-2 row">
                    <div class="col-md-10">
                      <select class="form-control form-select" v-model="ed.user_id">
                        <option v-for="(item, index) in academicDebtService.pps" :value="item.id" :key="index">
                          {{ item.lastname }} {{ item.firstname }}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

              </div>

              <div class="mt-3 row">
                <div class="col-md-4">
                  <Button icon="pi pi-plus" class="p-button-rounded p-button-secondary" @click="addDeveloper"/>
                </div>
                <div v-if="developers.length" class="col-md-8">
                  <Button label="Сохранить" class="p-button-rounded" @click="saveDevelopers"/>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End AddDeveloperModal -->


      <!-- DuplicatePassportEducationProgramModal -->
      <div class="modal fade" id="duplicateEducationProgramModal" tabindex="-1"
           aria-labelledby="duplicateEducationProgramModalLabel"
           aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="duplicateEducationProgramModalLabel">
                Дублирование паспорта образовательной программы
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mt-2" v-if="duplicateEducationProgramData">
                <h4>{{ duplicateEducationProgramData.education_speciality_name }}</h4>
                <div class="mt-2 mb-3">Код образовательной программы:
                  {{ duplicateEducationProgramData.education_speciality_code }}
                </div>
                <div class="mt-1 mb-1">Язык обучения:
                  {{ duplicateEducationProgramData.language.native_name }}
                </div>
                <div class="mt-1 mb-1">Набор: {{ duplicateEducationProgramData.admission_year }}</div>
                <div class="mt-2 mb-3">Приказ ГОСО:
                  {{ duplicateEducationProgramData.decreeGosoTemplate.decree }}
                </div>

                <div class="mt-1 mb-2">
                  <div class="mb-1 mt-1">Дублировать для набора:</div>

                  <div v-for="(year, idx) in admissionYearsFilter" :key="'yearKey'+idx"
                       class="form-check">
                    <input type="checkbox" :id="'year'+year" :value="year"
                           v-model="checkedAdmissionYears"
                           class="form-check-input">
                    <label :for="'year'+year" class="form-check-label">{{ year }}</label>
                  </div>
                  <div class="mt-2">Отмеченные года: {{ checkedAdmissionYears.join(', ') }}</div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                      @click="duplicateEducationProgram">
                Дублировать
              </button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Отменить
              </button>

            </div>
          </div>
        </div>
      </div>
      <!-- End DuplicatePassportEducationProgramModal -->


      <!-- Update Program Modal -->
      <div class="modal fade" id="updateEducationProgram" tabindex="-1"
           aria-labelledby="updateEducationProgramLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="updateEducationProgramLabel">Обновление</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" v-if="UPDATE_ITEM">

              <div class="col-md-12 mt-4">

                <div class="form-row">

                  <div class="form-group row mt-4">
                    <label class="col-md-3 col-form-label">Кафедра</label>
                    <div class="col-md-9">
                      <select class="form-control form-select"
                              @input="changeEducationProgramData(UPDATE_ITEM,'department_id', $event)">
                        <option
                            v-for="(item, index) in [{id:0,name_ru:'Не выбрано'},...educationdiscipline_form.departmentField]"
                            :value="item.id"
                            :selected="item.id == UPDATE_ITEM.department_id"
                            :key="index">{{ item.name_ru }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label class="col-md-3 col-form-label">Набор</label>
                    <div class="col-md-9">
                      <select class="form-control form-select" name="admission_year"
                              id="admission_year"
                              @input="changeEducationProgramData(UPDATE_ITEM,'admission_year', $event)">
                        <option value="0" :selected="UPDATE_ITEM.admission_year==0">Не выбрано
                        </option>
                        <option value="2017" :selected="UPDATE_ITEM.admission_year==2017">2017
                        </option>
                        <option value="2018" :selected="UPDATE_ITEM.admission_year==2018">2018
                        </option>
                        <option value="2019" :selected="UPDATE_ITEM.admission_year==2019">2019
                        </option>
                        <option value="2020" :selected="UPDATE_ITEM.admission_year==2020">2020
                        </option>
                        <option value="2021" :selected="UPDATE_ITEM.admission_year==2021">2021
                        </option>
                        <option value="2022" :selected="UPDATE_ITEM.admission_year==2022">2022
                        </option>
                        <option value="2023" :selected="UPDATE_ITEM.admission_year==2023">2023
                        </option>
                        <option value="2024" :selected="UPDATE_ITEM.admission_year==2024">2024
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="study_level_id" class="col-md-3 col-form-label">
                      Уровень обучения
                    </label>
                    <div class="col-md-9">
                      <select class="form-control form-select" id="study_level_id"
                              @input="changeEducationProgramData(UPDATE_ITEM,'study_level_id', $event)">
                        <option v-for="(item, index) in [{id:0,name:'Не выбрано'},...educationprogram_form.studyLevel]"
                                :value="item.id"
                                :selected="UPDATE_ITEM.study_level_id == item.id"
                                :key="index">{{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label class="col-md-3 col-form-label">Область образования</label>
                    <div class="col-md-9">
                      <select class="form-control form-select"
                              @input="changeEducationProgramData(UPDATE_ITEM,'education_field_id', $event)">
                        <option
                            v-for="(item, index) in [{id:0,name:'Не выбрано'}, ...educationprogram_form.educationField]"
                            :value="item.id"
                            :selected="UPDATE_ITEM.education_field_id==item.id"
                            :key="index">{{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="update_education_direction" class="col-md-3 col-form-label">
                      Направление подготовки
                    </label>
                    <div class="col-md-9">
                      <select class="form-control form-select" id="update_education_direction"
                              @input="changeEducationProgramData(UPDATE_ITEM,'education_direction_id', $event)">
                        <option
                            v-for="(item, index) in [{id:0,name:'Не выбрано'}, ...getUpdateDirectionType(UPDATE_ITEM.education_field_id)]"
                            :value="item.id"
                            :selected="UPDATE_ITEM.education_direction_id==item.id"
                            :key="index">{{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label class="col-md-3 col-form-label">Группа образовательных программ</label>
                    <div class="col-md-9">
                      <select class="form-control form-select"
                              @input="changeEducationProgramData(UPDATE_ITEM,'education_groups_id', $event)">
                        <option
                            v-for="(item, index) in [{id:0,code:'Не выбрано',name:''}, ...educationprogram_form.educationGroups]"
                            :value="item.id"
                            :selected="UPDATE_ITEM.education_groups_id==item.id"
                            :key="index">{{ item.code }} {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="education_program_semester_type_id" class="col-md-3 col-form-label">Тип семестра</label>
                    <div class="col-md-9">
                      <select class="form-control form-select" id="education_program_semester_type_id"
                              @input="changeEducationProgramData(UPDATE_ITEM,'education_program_semester_type_id', $event)">
                        <option v-for="(item, index) in [{id:0,name:'Не выбрано'},...educationprogram_form.educationProgramSemesterType]"
                                :value="item.id"
                                :selected="UPDATE_ITEM.education_program_semester_type_id==item.id"
                                :key="index">{{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="language" class="col-md-3 col-form-label">Язык обучения</label>
                    <div class="col-md-9">
                      <select class="form-control form-select" id="language"
                              @input="changeEducationProgramData(UPDATE_ITEM,'language_id', $event)">
                        <option v-for="(item, index) in [{id:0,name:'Не выбрано'},...educationprogram_form.languages]"
                                :value="item.id"
                                :selected="UPDATE_ITEM.language_id==item.id"
                                :key="index">{{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>


                  <div class="form-group row mt-4">
                    <label for="update_education_speciality_code" class="col-md-3 col-form-label">
                      Код образовательной программы
                    </label>
                    <div class="col-md-9">
                      <textarea id="update_education_speciality_code" type="text" class="form-control"
                                placeholder="Код образовательной программы"
                                :value="UPDATE_ITEM.education_speciality_code"
                                @change="changeEducationProgramData(UPDATE_ITEM,'education_speciality_code', $event)"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mt-4">
                    <label for="update_education_speciality_name" class="col-md-3 col-form-label">
                      Название образовательной программы
                    </label>
                    <div class="col-md-9">
                      <textarea id="update_education_speciality_name" type="text" class="form-control"
                                placeholder="Название образовательной программы"
                                :value="UPDATE_ITEM.education_speciality_name"
                                @change="changeEducationProgramData(UPDATE_ITEM,'education_speciality_name', $event)"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mt-4">
                    <label for="update_ep_aim" class="col-md-3 col-form-label">
                      Цель образовательной программы
                    </label>
                    <div class="col-md-9">
                      <textarea id="update_ep_aim" type="text" class="form-control"
                                placeholder="Цель образовательной программы"
                                :value="UPDATE_ITEM.ep_aim"
                                @change="changeEducationProgramData(UPDATE_ITEM,'ep_aim', $event)">
                      </textarea>
                    </div>
                  </div>


                  <div class="form-group row mt-4">
                    <label for="update_partner_university_sop" class="col-md-3 col-form-label">ВУЗ-партнер
                      (СОП)</label>
                    <div class="col-md-9">
                      <input id="update_partner_university_sop" type="text" class="form-control"
                             placeholder="ВУЗ-партнер (СОП)"
                             :value="UPDATE_ITEM.partner_university_sop"
                             @change="changeEducationProgramData(UPDATE_ITEM,'partner_university_sop', $event)">
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="update_partner_university_ddop" class="col-md-3 col-form-label">ВУЗ-партнер
                      (ДДОП)</label>
                    <div class="col-md-9">
                      <input id="update_partner_university_ddop" type="text" class="form-control"
                             placeholder="ВУЗ-партнер (ДДОП)"
                             :value="UPDATE_ITEM.partner_university_ddop"
                             @change="changeEducationProgramData(UPDATE_ITEM,'partner_university_ddop', $event)">
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label class="col-md-3 col-form-label">Форма обучения</label>
                    <div class="col-md-9">
                      <select class="form-control form-select"
                              @input="changeEducationProgramData(UPDATE_ITEM,'study_form_id', $event)">
                        <option v-for="(item, index) in educationprogram_form.studyForm"
                                :value="item.id"
                                :selected="UPDATE_ITEM.study_form_id==item.id"
                                :key="index">{{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="update_credit_volume" class="col-md-3 col-form-label">Объем
                      кредитов</label>
                    <div class="col-md-9">
                      <input id="update_credit_volume" type="text" class="form-control"
                             placeholder="Объем кредитов"
                             :value="UPDATE_ITEM.credit_volume"
                             @change="changeEducationProgramData(UPDATE_ITEM,'credit_volume', $event)">
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="update_academic_degree" class="col-md-3 col-form-label">Присуждаемая
                      академическая степень</label>
                    <div class="col-md-9">
                      <select class="form-control form-select" id="update_degree_type_id"
                              @input="changeEducationProgramData(UPDATE_ITEM,'degree_type_id', $event)">
                        <option
                            v-for="(item, index) in [{id:0,name_ru:'Не выбрано'},...educationprogram_form.degreeTypes]"
                            :value="item.id"
                            :selected="UPDATE_ITEM.degree_type_id==item.id"
                            :key="index">{{ item.name_ru }}
                        </option>
                      </select>
                      <!--                      <input id="update_academic_degree" type="text" class="form-control"-->
                      <!--                             placeholder="Присуждаемая академическая степень"-->
                      <!--                             :value="UPDATE_ITEM.academic_degree"-->
                      <!--                             @change="changeEducationProgramData(UPDATE_ITEM,'academic_degree', $event)">-->
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="update_application_availability" class="col-md-3 col-form-label">Наличие
                      приложения к лицензии на направление подготовки кадров</label>
                    <div class="col-md-9">
                      <input id="update_application_availability" type="text" class="form-control"
                             placeholder="Наличие приложения к лицензии на направление подготовки кадров"
                             :value="UPDATE_ITEM.application_availability"
                             @change="changeEducationProgramData(UPDATE_ITEM,'application_availability', $event)">
                    </div>
                  </div>

                  <div class="form-group row mt-4">
                    <label for="decree_goso_template_id"
                           class="col-md-3 col-form-label">Приказ ГОСО</label>
                    <div class="col-md-9">
                      <select class="form-control form-select" name="decree_goso_template_id"
                              id="decree_goso_template_id"
                              @input="changeEducationProgramData(UPDATE_ITEM,'decree_goso_template_id', $event)">
                        <option v-for="(item, index) in decreeGosoTemplates_form.decreeGosoTemplatesInfos"
                                :value="item.id"
                                :selected="UPDATE_ITEM.decree_goso_template_id==item.id"
                                :key="index">{{ item.decree }}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

              </div>


            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                      @click="updateEducationField(UPDATE_ITEM.id)">
                Обновить
              </button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End Update Program Modal -->


      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#createEducationProgram"
                  @click="setFieldId">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить паспорт образовательной
            программы
          </button>
        </div>
      </div>

      <!-- table start -->
      <!--            <div class="table-responsive-md mt-4">-->
      <!--                <table class="table table-striped table-bordered">-->
      <!--                    <thead>-->
      <!--                    <tr>-->
      <!--                        <th scope="col">№</th>-->
      <!--                        <th scope="col">Код</th>-->
      <!--                        <th scope="col">Название ОП</th>-->
      <!--                        <th scope="col">Язык обучения</th>-->
      <!--                        <th scope="col">Год набора</th>-->
      <!--                        <th scope="col">Название приказа ГОСО</th>-->
      <!--                        <th scope="col">Редактировать</th>-->
      <!--&lt;!&ndash;                        <th scope="col">Удалить</th>&ndash;&gt;-->
      <!--                        <th scope="col">Дублировать</th>-->
      <!--                    </tr>-->
      <!--                    </thead>-->
      <!--                    <tbody>-->

      <!--                    <tr v-for="(item,index) in educationprogram_form.educationProgramInfos" :key="index">-->
      <!--                        <th scope="row">{{ (page - 1) * 100 + index + 1 }}</th>-->
      <!--                        <td>-->
      <!--                            {{ item.education_speciality_code }}-->
      <!--                        </td>-->
      <!--                        <td>-->

      <!--                            <router-link-->
      <!--                                    :to="'/formation-education-program?education_program_id=' + item.id + '&decree_goso_template_id=' + item.decree_goso_template_id">-->
      <!--                                {{ item.education_speciality_name }}-->
      <!--                            </router-link>-->
      <!--                        </td>-->

      <!--                        <td>-->
      <!--                            {{ item.language ? item.language.native_name : '' }}-->
      <!--                        </td>-->

      <!--                        <td>-->
      <!--                            {{ item.admission_year }}-->
      <!--                        </td>-->

      <!--                        <td>-->
      <!--                            {{ item.decreeGosoTemplate ? item.decreeGosoTemplate.decree : '' }}-->
      <!--                        </td>-->

      <!--                        <td>-->
      <!--                            <button type="button" class="btn btn-warning" data-bs-toggle="modal"-->
      <!--                                    data-bs-target="#updateEducationProgram"-->
      <!--                                    @click="updateEducation(item.id)">-->
      <!--                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>-->
      <!--                            </button>-->
      <!--                        </td>-->
      <!--&lt;!&ndash;                        <td>&ndash;&gt;-->
      <!--&lt;!&ndash;                            <button type="button" class="btn btn-danger" data-bs-toggle="modal"&ndash;&gt;-->
      <!--&lt;!&ndash;                                    data-bs-target="#deleteEducationProgramModal"&ndash;&gt;-->
      <!--&lt;!&ndash;                                    @click="opendeleteEducationProgramModal(item.id)">&ndash;&gt;-->
      <!--&lt;!&ndash;                                <i class="fa fa-trash-o" aria-hidden="true"></i>&ndash;&gt;-->
      <!--&lt;!&ndash;                            </button>&ndash;&gt;-->
      <!--&lt;!&ndash;                        </td>&ndash;&gt;-->
      <!--                        <td class="text-center">-->
      <!--                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"-->
      <!--                                    data-bs-target="#duplicateEducationProgramModal"-->
      <!--                                    @click="openDuplicateEducationProgramModal(item.id)">-->
      <!--                                <i class="far fa-clone"></i>-->
      <!--                            </button>-->
      <!--                        </td>-->

      <!--                    </tr>-->
      <!--                    </tbody>-->
      <!--                </table>-->
      <!--            </div>-->
      <!-- table end -->
      <!--            <nav v-if="pageCount" class="d-flex justify-content-center" aria-label="Page navigation example">-->
      <!--                <Paginate-->
      <!--                        v-model="page"-->
      <!--                        :page-count="pageCount"-->
      <!--                        :click-handler="paginateHandler"-->
      <!--                        :prev-text="'Предыдущий'"-->
      <!--                        :next-text="'Следующий'"-->
      <!--                        :container-class="'pagination'"-->
      <!--                        :page-class="'page-item'">-->
      <!--                </Paginate>-->
      <!--            </nav>-->

      <DataTable class="mt-4" :value="educationprogram_form.educationProgramInfos" :paginator="true" :rows="10"
                 showGridlines
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 :globalFilterFields="['education_speciality_name', 'education_speciality_code']"
                 v-model:filters="filters"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                 responsiveLayout="scroll">

        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск ОП"/>
              </span>
          </div>
        </template>

        <Column field="education_speciality_code" header="Код ОП"></Column>

        <Column header="Название ОП">
          <template #body="{data}">
            <router-link
                :to="'/formation-education-program?education_program_id=' + data.id + '&decree_goso_template_id=' + data.decree_goso_template_id">
              {{ data.education_speciality_name }}
            </router-link>
          </template>
        </Column>
        <Column field="language.native_name" header="Язык обучения">
          <template #body="{data}">
            {{ data.language ? data.language.native_name : '' }}
          </template>
          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="studyLanguages" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{ slotProps.value }}</span>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <span>{{ slotProps.option }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column field="admission_year" header="Год набора">

          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="admissionYears" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{ slotProps.value }}</span>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <span>{{ slotProps.option }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column header="Название приказа ГОСО">
          <template #body="{data}">
            {{ data.decreeGosoTemplate ? data.decreeGosoTemplate.decree : '' }}
          </template>
        </Column>
        <Column header="Результаты обучения">
          <template #body="{data}">
            <router-link :to="'/education-program-result?education_program_id=' + data.id" target="_blank">
              Перейти
            </router-link>
          </template>
        </Column>
        <Column header="Редактировать">
          <template #body="{data}">
            <button type="button" class="btn btn-warning" data-bs-toggle="modal"
                    data-bs-target="#updateEducationProgram"
                    @click="updateEducation(data.id)">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
          </template>
        </Column>

        <Column header="Дублировать">
          <template #body="{data}">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#duplicateEducationProgramModal"
                    @click="openDuplicateEducationProgramModal(data.id)">
              <i class="far fa-clone"></i>
            </button>
          </template>
        </Column>


        <Column header="Добавление разработчика">
          <template #body="{data}">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#AddDeveloperModal"
                    @click="openAddDevelopers(data.id)">
              <i class="pi pi-plus"></i>
            </button>
          </template>
        </Column>

        <Column header="Привязка майнор ОП">
          <template #body="{data}">
            <Button icon="pi pi-link" class="p-button-primary mx-3"
                    @click="openAddMinor(data.id)"/>
          </template>
        </Column>

      </DataTable>


      <Dialog header="Привязка майнора"
              v-model:visible="displayAddMinor"
              :style="{width: '98%', maxWidth: '900px'}" :modal="true" :closable="false">


        <div class="col-md-12" style="height: 500px">


          <ul>
            При удалении привязки удаляются и привязки в формировании ОП!!!
            <li v-for="(item, index) of educationprogram_form?.educationProgramInfos?.find(i=>i.id == selected_education_program_id)?.educationProgramMinors" :key="index">
              {{item.minorEducationProgram.education_speciality_code}} {{item.minorEducationProgram.education_speciality_name}} - {{item.minorEducationProgram.admission_year}}

              <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                      @click="deleteMinor(item.id)"/>
            </li>
          </ul>


          <Button icon="pi pi-plus" label="Добавить майнор" @click="addRowMinor"/>
          <div class="form-group row mt-4" v-for="(minor, index) in minors" :key="index">
            <div class="d-flex flex-row-reverse">
              <i class="fa fa-times" @click="deleteRowMinor(index)"></i>
            </div>
            <label class="col-md-3 col-form-label">Поиск майнор ОП</label>
            <div class="col-md-9">
              <DropdownListMinor
                  :options="options"
                  @change="changeSelectedMinor(index, $event)"
                  @search="onSearch"/>
            </div>
          </div>
        </div>


        <template #footer>
          <Button label="Отмена" icon="pi pi-times" @click="closeAddMinor" class="p-button-text"/>
          <Button label="Привязать" icon="pi pi-check"
                  :loading="btnSaveDisabled"
                  @click="saveMinors"/>
        </template>
      </Dialog>


    </div>

  </div>
</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
//import Paginate from '../common/Paginate.vue'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import DeletePassportEducationProgramModal
  from '@/components/education-programme/passport-education-program/DeletePassportEducationProgramModal.vue'
import AddPassportEducationProgramModal
  from '@/components/education-programme/passport-education-program/AddPassportEducationProgramModal.vue'
import DropdownListMinor from "@/components/common/DropdownListMinor.vue";
import educationprogram from "@/store/modules/education-programme/educationprogram";

export default {
  name: "EducationProgram",
  components: {
    //Paginate,
    DeletePassportEducationProgramModal,
    AddPassportEducationProgramModal,
    DropdownListMinor
  },

  data() {
    return {
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'education_speciality_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'education_speciality_code': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'admission_year': {value: null, matchMode: FilterMatchMode.EQUALS},
        'language.native_name': {value: null, matchMode: FilterMatchMode.EQUALS},

      },
      studyLanguages: ['русский язык', 'қазақ тілі', 'English'],
      //deleteEducationProgramId: '',
      page: +this.$route.query.page || 1,
      updateId: 0,
      admissionYears: [2018, 2019, 2020, 2021, 2022, 2023, 2024],
      checkedAdmissionYears: [],
      developers: [],
      typeDevelopers: [
        {
          id: 1,
          name: 'Руководитель'
        },
        {
          id: 0,
          name: 'Участник'
        },
      ],
      education_program_id: null,

      displayAddMinor: false,
      options: [],
      minors: [],
      selected_education_program_id: null,
      btnSaveDisabled: false,

    }
  },
  computed: {
    educationprogram() {
      return educationprogram
    },
    ...mapState('educationprogram', ['educationprogram_form', 'pageCount', 'epDevelopers']),
    ...mapState('decreeGosoTemplates', ['decreeGosoTemplates_form']),
    ...mapGetters('educationprogram', ['UPDATE_ITEM']),
    ...mapState('educationdiscipline', ['educationdiscipline_form']),
    ...mapState('academicDebtService', ['academicDebtService']),

    duplicateEducationProgramData() {
      if (this.educationprogram_form.duplicateId) {
        return this.educationprogram_form.educationProgramInfos.find(i => i.id == this.educationprogram_form.duplicateId)
      }
      return null
    },
    admissionYearsFilter() {
      if (this.duplicateEducationProgramData) {
        return this.admissionYears.filter(i => i !== +this.duplicateEducationProgramData.admission_year)
      }
      return []
    },
    // checkCreditSum() {
    //
    //
    //   let allCreditSum = this.minors?.reduce((sum, item) => +item.credit_sum + sum, 0)
    //
    //   if (this.minors.length > 0) {
    //     if (parseInt(allCreditSum) % parseInt(this.minors[0]?.credit_sum)  == 0) {
    //       return false
    //     }
    //     else {
    //       return true
    //     }
    //   }
    //   else {
    //     return false
    //   }
    //
    //
    //
    // },


  },

  methods: {
    ...mapActions('educationdiscipline', ['GET_DEPARTMENT_NAMES']),
    ...mapActions('educationprogram', ['POST_EDUCATION_PROGRAM_DATA', 'PUT_EDUCATION_PROGRAM_DATA', 'GET_EDUCATION_PROGRAM_DATA',
      'GET_EDUCATION_FIELD_NAMES', 'GET_EDUCATION_DIRECTION_NAMES', 'GET_EDUCATION_GROUPS_NAMES',
      'GET_STUDY_FORM_NAMES', 'GET_STUDY_LEVEL_NAMES', 'GET_DEGREE_TYPES',
      'GET_EP_DEVELOPERS', 'POST_EP_DEVELOPERS', 'PUT_EP_DEVELOPERS', 'DELETE_EP_DEVELOPERS',
        'GET_MINOR_EDUCATION_PROGRAMS_BY_NAME', 'POST_EDUCATION_PROGRAM_MINORS', 'DELETE_EDUCATION_PROGRAM_MINORS'
    ]),
    ...mapMutations('educationprogram', ['UPDATE_EDUCATION_PROGRAM_DATA', 'SET_UPDATE_ID', 'SET_DELETE_EDUCATION_PROGRAM_ID',
      'SET_DUPLICATE_EDUCATION_PROGRAM_ID']),
    ...mapActions('academicDebtService', ['GET_PPS']),



    async deleteMinor(id) {
      await this.DELETE_EDUCATION_PROGRAM_MINORS(id)
      await this.GET_EDUCATION_PROGRAM_DATA()
    },
    async saveMinors() {
      this.btnSaveDisabled = true
      this.displayAddMinor = false

      let form = this.minors.map(i => ({
        minor_education_program_id: i.minor_education_program_id,
        education_program_id: i.education_program_id,

      }))
      console.log(form, 'submit minors form')
      let postData = await this.POST_EDUCATION_PROGRAM_MINORS(form)
      if (postData) {
        await this.GET_EDUCATION_PROGRAM_DATA()
        this.$message({text: 'Майноры привязаны'})
      }
      this.minors = []
      this.selected_education_program_id = null
      this.btnSaveDisabled = false
    },


    addRowMinor() {
      this.minors.push({
        minor_education_program_id: null,
        education_program_id: this.selected_education_program_id,
        credit_sum: null
      });
      console.log(this.minors, 'minors')
    },
    deleteRowMinor(index) {
      this.minors.splice(index, 1);
      console.log(this.minors, 'minors')
    },

    changeSelectedMinor(index, e) {
      let selectedMinorId = e.id
      let selectedCreditSum = e.credit_sum
      console.log(selectedMinorId, 'selectedEducationDisciplineId')
      this.minors[index].minor_education_program_id = selectedMinorId
      this.minors[index].credit_sum = selectedCreditSum
      console.log(this.minors, 'minors')
    },
    onSearch(search) {
      if (search.length > 3) {
        this.GET_MINOR_EDUCATION_PROGRAMS_BY_NAME({name: search, educationProgramId: this.selected_education_program_id}).then(json => (this.options = json));
      }
    },

    openAddMinor(selected_education_program_id) {
      this.selected_education_program_id = selected_education_program_id
      this.displayAddMinor = true
    },

    closeAddMinor() {
      this.displayAddMinor = false
    },

    async duplicateEducationProgram() {
      console.log(this.checkedAdmissionYears, 'checkedAdmissionYears')
      console.log(this.educationprogram_form.duplicateId, 'duplicateId')
      const educationProgramData = {...this.educationprogram_form.educationProgramInfos.find(i => i.id == this.educationprogram_form.duplicateId)}

      delete educationProgramData.id
      delete educationProgramData.decreeGosoTemplate
      delete educationProgramData.language

      const educationPrograms = []
      this.checkedAdmissionYears.forEach(year => {
        educationPrograms.push({...educationProgramData, admission_year: year})
      })

      if (educationPrograms.length) {
        this.POST_EDUCATION_PROGRAM_DATA(educationPrograms).then(res => {
          if (res.success == true) {
            this.GET_EDUCATION_PROGRAM_DATA(this.page);
            this.$message({title: 'Дублирование', text: 'Данные успешно дублировались'})
          } else {
            const errorText = res.errors[0].map(err => err.message).join('\n');
            this.$error({title: 'Дублирование', text: errorText})
          }
        })
      }


    },
    updateEducation(id) {
      console.log(id, 'updateEducation')
      this.SET_UPDATE_ID(id)
    },
    async paginateHandler(page) {
      this.page = page
      await this.changePage()
    },
    async changePage() {
      this.$router.push(`${this.$route.path}?page=${this.page}`)
      await this.GET_EDUCATION_PROGRAM_DATA(this.page)
    },
    async opendeleteEducationProgramModal(id) {
      await this.SET_DELETE_EDUCATION_PROGRAM_ID(id);
    },
    openDuplicateEducationProgramModal(id) {
      this.checkedAdmissionYears = []
      this.SET_DUPLICATE_EDUCATION_PROGRAM_ID(id);
    },
    async updateEducationField(id) {
      console.log(id, 'KSGSGSG')
      await this.PUT_EDUCATION_PROGRAM_DATA(id).then(res => {
        if (res.success == true) {
          this.GET_EDUCATION_PROGRAM_DATA()
          this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Ошибка при обновлении', text: errorText})
        }
      })
    },
    getUpdateDirectionType(id) {
      let directionType = this.educationprogram_form.educationDirection.filter(i => i.education_field_id == id)
      console.log(directionType, 'directionType')
      return directionType
    },

    changeEducationProgramData(item, property, e, val = 'value') {
      const value = e.target[val]
      console.log(value, "value")
      this.UPDATE_EDUCATION_PROGRAM_DATA({item, property, value})
    },

    setFieldId() {
      this.changeNewEducationProgramData(this.educationprogram_form.newEducationProgramInfos[0], 'education_field_id', {target: {value: this.educationprogram_form.educationField[this.educationprogram_form.educationField.length - 1].id}})
    },
    async openAddDevelopers(education_program_id) {
      this.education_program_id = education_program_id
      this.developers = await this.GET_EP_DEVELOPERS(education_program_id)
    },
    addDeveloper() {
      this.developers.push({
        user_id: null,
        is_supervisor: 0
      })
    },
    async deleteDeveloper(idx) {
      if (this.developers[idx].id) {
        const res = await this.DELETE_EP_DEVELOPERS(this.developers[idx].id)
        if (!res) {
          return
        }
      }
      this.developers.splice(idx, 1)
      this.$message({title: 'Удалено'})
    },
    async saveDevelopers() {
      const postForm = this.developers.filter(i => !i.id).map(i => ({
        ...i,
        education_program_id: this.education_program_id
      }))
      const putForm = this.developers.filter(i => !!i.id)

      let postRes = true
      if (postForm.length) {
        postRes = await this.POST_EP_DEVELOPERS(postForm)
      }

      let putRes = true
      if (putForm.length) {
        putRes = await this.PUT_EP_DEVELOPERS(putForm)
      }

      if (postRes && putRes) {
        this.developers = await this.GET_EP_DEVELOPERS(this.education_program_id)
        this.$message({title: 'Сохранено'})
      }
    }
  },
  async mounted() {
    await this.GET_DEPARTMENT_NAMES();
    await this.GET_EDUCATION_PROGRAM_DATA();
    await this.GET_EDUCATION_FIELD_NAMES();
    await this.GET_EDUCATION_GROUPS_NAMES();
    await this.GET_EDUCATION_DIRECTION_NAMES();
    await this.GET_STUDY_FORM_NAMES();
    await this.GET_STUDY_LEVEL_NAMES();
    await this.GET_DEGREE_TYPES();
    await this.GET_PPS(1)

  }
}
</script>

<style scoped>

</style>
