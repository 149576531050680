<template>
  <!-- Delete Education Program Modal -->
  <div class="modal fade" id="deleteEducationProgramModal" tabindex="-1"
       aria-labelledby="deleteEducationProgramModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteEducationProgramModalLabel">Удаление паспорта образовательной
            программы</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 mt-4">
            <p>Вы точно хотите удалить образовательную программу?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  @click="deleteEducationProgram">
            Удалить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Отменить
          </button>

        </div>
      </div>
    </div>
  </div>
  <!-- End Delete Education Program Modal -->
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "DeletePassportEducationProgramModal",

  data() {
    return {
    }
  },
  computed: {
    ...mapState('educationprogram', ['educationprogram_form']),
  },

  methods: {
    ...mapActions('educationprogram', ['GET_EDUCATION_PROGRAM_DATA', 'DELETE_EDUCATION_PROGRAM_DATA']),



    async deleteEducationProgram() {
      await this.DELETE_EDUCATION_PROGRAM_DATA(this.educationprogram_form.deleteEducationProgramId);
      this.$message({title: 'Удаление', text: 'Успешное удаление'})
      await this.GET_EDUCATION_PROGRAM_DATA();
    }
  },
  async mounted() {

  }
}
</script>

<style scoped>

</style>